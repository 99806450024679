
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        










































































.net-antivirus,
[class*='net-antivirus--'] {
  ::v-deep {
    .net-antivirus__cards {
      .card-small__items__item {
        align-items: flex-start;
        text-align: left;
        border-radius: 8px;
      }

      .card-small-item__title {
        @include mq(xl) {
          font-size: 3rem;
        }
      }

      .card-small-item__text {
        font-weight: 400;
      }

      .card-small-item__bg {
        bottom: -5rem;
        height: 90%;
      }
    }
  }
}

.net-antivirus__hero {
  ::v-deep .icon {
    width: 50rem;
    max-width: 80vw;
    height: auto;
    max-height: none;
    fill: $white;

    svg {
      width: 100%;
    }

    @include mq(xl) {
      width: 70rem;
    }
  }

  ::v-deep .hero__slides__item__title {
    @extend %visually-hidden;
  }
}

.custom-page__hero__btn {
  margin-top: $spacing * 2;
}

.net-antivirus__pricing__promo {
  margin-bottom: $spacing * 2;
}

.net-antivirus__pricing__promo__box {
  border-radius: 3px;
}

.net-antivirus__pricing__promo__text {
  margin-left: $spacing / 2;
  color: $c-orange;
}

.net-antivirus__table {
  position: relative;
}

.compatible-devices__label {
  margin-bottom: $spacing;
  font-weight: 900;
  text-transform: uppercase;
}

.compatible-devices__logos {
  .icon {
    margin-right: $spacing;
    fill: $c-gray-darker;
  }

  @include mq(m) {
    .icon {
      margin-right: $spacing * 1.5;
    }
  }
}
